
ol ol {
  list-style-type: lower-alpha;
}
ol ol ol {
  list-style-type: upper-roman;
}
@media print {
  article {
    column-fill: auto;
    column-count: 2;
    column-width: 17em;
    column-gap: 3em;
    height: 25.1cm;
    page-break-after: always;
  }
  #admin-section {
    font-size: 70%;
  }
   p {
    display: block;
  }
  .unpaid-subs, #account-button, .noprint  {
    display: none;
  }
  .MuiTypography-h5 {
    font-size: 1.2rem !important;
  }
  .MuiTypography-h6 {
    font-size: 1.0rem !important;
  }
  .summary-total {
    border-top: dashed black 1px;
    border-bottom: dashed black 1px;
    /* background-color: black; */
    /* color: white; */
  }
  .MuiListSubheader-sticky {
    position: relative !important;
    z-index: auto;
  }

}
.MuiListSubheader-sticky { background-color: white !important; }
article { margin-top: 1em;}
