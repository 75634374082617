body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@keyframes headShake {

  0 {
    transform: translateX(0)
  }

  12.5% {
    transform: translateX(-6px) rotateY(-9deg) skewY(1deg)
  }

  37.5% {
    transform: translateX(5px) rotateY(4.5deg) skewY(-1deg)
  }

  62.5% {
    transform: translateX(-3px) rotateY(-2.25deg) skewY(0)
  }

  87.5% {
    transform: translateX(2px) rotateY(3deg)
  }

  100% {
    transform: translateX(0)
  }
}

.headShake {
  animation-duration: .5s;
  animation-timing-function: cubic-bezier(0,.23,1,.71);
  animation-name: headShake;
  transform-orgin:center bottom
}

a {
  color: inherit;
  font-weight: 700;
}
